import {BannerContainer, Banner} from "../components/banner/banner";
import {useRouteMatch} from "react-router-dom";

class BannerTool {
    static routeMatcher(path) {
        let match = useRouteMatch(path);
        return match ? match.isExact : false;
    }
}

const checkAuthentication = (props) => {
    const {currentUser} = props;
    return !!currentUser;
};

const checkUserRole = (props, role) => {
    const {currentUser} = props;
    return checkAuthentication(props) && currentUser['role'] === role;
};

const FinleapConnectBannerItem = Banner.light("Qwist");
const QwacCertificateBanner = Banner.dark("QWAC Certificate");
const QsealCertificateBanner = Banner.dark("Qseal Certificate");
const InvitationBanner = Banner.dark("Invitation");
const ListCertificatesBanner = Banner.dark("Clients&Certificates");
const OrganizationBanner = Banner.dark('Organizations');

const ListCertificatesBannerContainer = new BannerContainer('certificates', [FinleapConnectBannerItem, ListCertificatesBanner], (props) => {
    return checkUserRole(props, 'USER') && (BannerTool.routeMatcher("/") || BannerTool.routeMatcher("/certificates"));
});

const ListOrganizationsBannerContainer = new BannerContainer('organizations', [FinleapConnectBannerItem, OrganizationBanner], (props) => {
    return checkUserRole(props, 'ADMIN') && (BannerTool.routeMatcher("/") || BannerTool.routeMatcher("/organizations/create"));
});

export const bannerConfig = [
    new BannerContainer('login', FinleapConnectBannerItem, () => { return BannerTool.routeMatcher('/login') }),
    new BannerContainer('qwac', [FinleapConnectBannerItem, QwacCertificateBanner], () => { return BannerTool.routeMatcher('/certificates/qwac')} ),
    new BannerContainer('qseal', [FinleapConnectBannerItem, QsealCertificateBanner], () => { return BannerTool.routeMatcher('/certificates/qseal')} ),
    new BannerContainer('invitation', [FinleapConnectBannerItem, InvitationBanner], () => { return BannerTool.routeMatcher('/invitations') }),
    ListCertificatesBannerContainer,
    ListOrganizationsBannerContainer
];