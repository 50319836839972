import React from 'react';
import {NavLink} from "react-router-dom";

export const Logo = () => {
    return (
        <NavLink to="/" className={"logo"}>
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 95 27" style={{enableBackground: "new 0 0 95 27"}} xmlSpace="preserve">
                <polygon className="st0" points="45.6,7.9 49.2,20.7 52.6,7.9 57.4,7.9 51.8,26.7 46.7,26.7 43.1,13.8 39.6,26.7 34.4,26.7 28.9,7.9
	33.7,7.9 37,20.7 40.6,7.9 "/>
                <rect x="59.3" y="7.9" className="st0"  width="4.8" height="18.8"/>
                <path className="st0" d="M70.7,26.2c-1.2-0.6-2.2-1.3-2.9-2.3c-0.7-1-1.1-2-1.2-3.2h4.8c0.1,0.7,0.4,1.3,1.1,1.8
	c0.6,0.5,1.4,0.7,2.3,0.7c0.9,0,1.6-0.2,2.1-0.5c0.5-0.4,0.8-0.8,0.8-1.4c0-0.6-0.3-1.1-0.9-1.4c-0.6-0.3-1.6-0.6-3-1
	c-1.4-0.3-2.6-0.7-3.4-1.1c-0.9-0.4-1.7-0.9-2.3-1.7c-0.6-0.7-1-1.8-1-3c0-1,0.3-2,0.9-2.9c0.6-0.9,1.5-1.5,2.6-2
	c1.1-0.5,2.4-0.7,4-0.7c2.2,0,4,0.6,5.4,1.7c1.3,1.1,2.1,2.6,2.2,4.5h-4.5c-0.1-0.7-0.4-1.3-0.9-1.8c-0.6-0.4-1.3-0.7-2.2-0.7
	c-0.9,0-1.5,0.2-2,0.5c-0.5,0.3-0.7,0.8-0.7,1.3c0,0.6,0.3,1.1,0.9,1.4c0.6,0.3,1.6,0.7,3,1c1.4,0.3,2.5,0.7,3.4,1.1
	c0.9,0.4,1.6,0.9,2.3,1.7s1,1.8,1,3c0,1.1-0.3,2.1-0.9,2.9c-0.6,0.9-1.5,1.5-2.6,2c-1.1,0.5-2.4,0.7-3.9,0.7
	C73.3,27,71.9,26.7,70.7,26.2"/>
                <path className="st0" d="M90.8,11.8v9.1c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.8,0.4,1.5,0.4H95v4h-3c-4,0-6-1.9-6-5.8v-9.1h-2.2V7.9H86v-5
	h4.8v5H95v3.9H90.8z"/>
                <path className="st0" d="M4.8,13.3c0-4.7,3.8-8.6,8.6-8.6c4.7,0,8.6,3.8,8.6,8.6c0,4.7-3.8,8.6-8.6,8.6C8.6,21.9,4.8,18.1,4.8,13.3
	 M28.9,22.2h-5.6c2.1-2.4,3.4-5.5,3.4-8.9C26.7,6,20.7,0,13.3,0C6,0,0,6,0,13.3c0,7.4,6,13.3,13.3,13.3h16.8L28.9,22.2z"/>
                <rect x="59.3" y="0.3" className="st0" width="4.8" height="4.8"/>
</svg>
        </NavLink>
    );
};