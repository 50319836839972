import React from 'react';
import OrganizationSelector from "../OrganizationSelector";
import {Col, Container, Row} from "react-bootstrap";

const BannerComponent = (props) => {
    const {currentUser, organizations, currentOrganization, onChangeCurrentOrganization, items} = props;

    return (
        <div className={'banner-container'}>
            <div className={'banner-img-wrapper'}>
                <img src={`${window.location.origin}/image.png`} alt={"qwist"}/>
            </div>

            <div className={'banner-content'}>
                {items.map((value, index) => {
                    return (<BannerContainerComponent banner={value} key={index} {...props} />)
                })}
            </div>

            <Container>
                <Row>
                    <Col>
                        {currentUser != null && currentUser.role === 'USER' &&
                        <OrganizationSelector organizations={organizations}
                                              currentOrganization={currentOrganization}
                                              onChangeCurrentOrganization={onChangeCurrentOrganization}/>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const BannerContainerComponent = (props) => {
    const {elements, condition} = props.banner;
    let render;

    if (typeof condition === 'boolean') {
        render = condition;
    } else if (typeof condition === 'function') {
        render = condition(props);
    } else {
        render = false;
    }

    if (render && elements.length > 0) {
        return (
            <h1 className={'banner-title'}>
                {elements.map((value, index) => {
                    return (
                        <span key={index}>
                            <span className={`banner-box banner-box-${value.type}`}>{value.text}</span>
                            <br/>
                        </span>
                    )
                })}
            </h1>
        )
    } else {
        return ('');
    }
};

class BannerContainer {
    constructor(name, banners, condition) {
        this.name = name;
        this.elements = Array.isArray(banners) ? banners : [banners];
        this.condition = condition;
    }
}

class Banner {
    constructor(type, text) {
        this.type = type;
        this.text = text;
    }

    static light(text) {
        return new Banner(BannerType.LIGHT, text);
    }

    static dark(text) {
        return new Banner(BannerType.DARK, text);
    }

    static normal(text) {
        return new Banner(BannerType.NOrMAL, text);
    }
}

const BannerType = {
    DARK: 'dark',
    NOrMAL: 'normal',
    LIGHT: 'light'
};

export {BannerComponent, BannerContainer, Banner, BannerType};